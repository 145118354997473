'use client';

// Libraries
import dynamic from 'next/dynamic';
import { useCallback, useEffect, useRef, useState } from 'react';

// Variables
const NavigationBar = dynamic(() => import('@/components/NavigationBar'), {
  ssr: false,
});

export default function Header({ isTransparent }) {
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = useCallback(() => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top < 0);
    }
  }, [ref]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className={'sticky-header' + (!!isSticky ? ' sticky' : '')} ref={ref}>
      <NavigationBar isTransparent={!!isTransparent} />
    </div>
  );
}
